<template>
  <div class="phone-call">
    !! NOT IMPLEMENTED !!
    <v-card>
      <v-card-text>

        <user-picker
          :showPicker="contact.length < 1"
          :user-list="contact"
          :scope="accountScope"
          document-types="account-contacts"
          :can-close-chip="true"
          label="I talk with..."
        >
          <template v-slot:prepend>
            <div class="d-flex flex-column ml-5 mt-2">
              <v-icon class="d-flex">{{$icon('i.User')}}</v-icon>
              <small class="d-flex">{{$t('t.Contact')}}</small>
            </div>
          </template>
          <template v-slot:item="{parent, item}">
            <v-list-item-icon>
              <v-icon>{{item.group ? $icon('i.Users') : $icon('i.User')}}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-html="parent.genFilteredText(`${item.fullname} (${item.group ? item.group.name : 'Contact'})`)" />
            </v-list-item-content>
          </template>
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>No contacts availables</v-list-item-content>
            </v-list-item>
          </template>
        </user-picker>
      </v-card-text>
    </v-card>
    <work-items />

  </div>
</template>

<script>
import Scope from '@/pages/search/controllers/scope'

export default {
  components: {
    workItems: () => import('./keys-reasons'),
    UserPicker: () => import('@/components/user-picker')

  },
  data () {
    return {
      accountId: this.$route.params.id,
      contact: []
    }
  },
  computed: {
    accountScope () {
      return Scope.account(this.accountId)
    }
  },
  methods: {
  },
  props: {
    // task: Object
    tab: Object
  }
}
</script>

<style lang="stylus" scoped>
.phone-call
  display flex
  overflow auto
  flex-grow 1
  flex-wrap wrap

  .v-card, .v-skeleton-loader
    margin 0.5em
</style>
